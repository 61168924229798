import { create } from "superstruct";

import I18nLoaderException from "./I18nLoaderException";
import I18nLoaderInterface from "./I18nLoaderInterface";
import { I18nRecordsSchema } from "./schemas";

class ScriptTagLoader implements I18nLoaderInterface {
    constructor(private readonly scriptTagSelector: string) {}
    async load() {
        const scriptTag = document.querySelector(this.scriptTagSelector);
        let rawData;
        if (!scriptTag) {
            throw new I18nLoaderException("script tag not found", {
                scriptTagSelector: this.scriptTagSelector,
            });
        }
        if (!scriptTag?.innerHTML) {
            throw new I18nLoaderException("empty translations script tag");
        }
        try {
            rawData = JSON.parse(scriptTag.innerHTML) as unknown;
        } catch (error: unknown) {
            throw new I18nLoaderException("invalid json string");
        }
        try {
            const records = create(rawData, I18nRecordsSchema);
            return Promise.resolve(records);
        } catch (error: unknown) {
            throw new I18nLoaderException("validation error", {
                validationError: error,
            });
        }
    }
}

export default ScriptTagLoader;
